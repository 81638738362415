import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import ProfileWrapper from '../modules/apps/profile/ProfileWrapper'
import SuspensedView from '../general-components/SuspensedView/SuspensedView'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {Company} from '../modules/auth/components/Company'

import Terminal from '../pages/Terminal'

const PrivateRoutes = () => {
  const company = window.location.pathname.split('/')[1]
  const {currentCompany} = useAppContext()
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path='*'
          element={
            <Navigate
              to={
                currentCompany
                  ? company
                    ? `/${company}/terminal`
                    : '/kidling/terminal'
                  : company
                  ? `/${company}/company`
                  : '/kidling/company'
              }
            />
          }
        />
        <Route
          path='auth/*'
          element={<Navigate to={company ? `/${company}/company` : '/kidling/company'} />}
        />
        {/* Pages */}
        <Route
          path='company'
          element={
            <SuspensedView>
              <Company />
            </SuspensedView>
          }
        />

        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfileWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='terminal/*'
          element={
            <SuspensedView>
              <Terminal />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
