/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../auth'
import {readFileFrom} from '../../../../../actions/image'
import {updateUserInfo} from '../../../../../actions/users'
import {toast} from 'react-toastify'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import {getBase64} from '../../../../utils/getBase64'

function UserTopProfile() {
  const intl = useIntl()
  const {currentUser, setCurrentUser} = useAuth()
  const [preview, setPreview] = useState<any>(null)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [base64Image, setBase64Image] = useState<any>(null)
  const {setCurrentUserImage} = useAppContext()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (currentUser?.imagePath) {
      getImage()
    }
  }, [])

  useEffect(() => {
    if (!!base64Image) updateImage(base64Image)
  }, [base64Image])

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    getBase64(selectedFile, (result: string) => {
      setBase64Image(result)
    })

    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl)
    }
  }, [selectedFile])

  const updateImage = async (image: string) => {
    setIsLoading(true)
    const resp = await updateUserInfo({...currentUser, imagePath: image})
    setIsLoading(false)
    if (resp) {
      setCurrentUser(resp)
      setCurrentUserImage(image ? base64Image : null)
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    }
  }

  const getImage = async () => {
    const resp = await readFileFrom(currentUser?.imagePath ?? '')
    if (resp) setPreview(resp)
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='d-flex align-items-center'>
          <div className=' '>
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{
                backgroundImage: preview
                  ? `url(${preview})`
                  : 'url("/media/svg/avatars/blank.svg")',
              }}
            >
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{
                  backgroundImage: preview
                    ? `url(${preview})`
                    : 'url("/media/svg/avatars/blank.svg")',
                }}
              ></div>

              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                {isLoading ? (
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                ) : (
                  <i className='fa-solid fa-pencil'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                )}

                <input
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                  onChange={(e: any) =>
                    setSelectedFile(e.target?.files.length > 0 ? e.target?.files[0] : null)
                  }
                />
                <input type='hidden' name='avatar_remove' />
              </label>

              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
              >
                <i className='fa-solid fa-xmark'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </span>

              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                title='Remove avatar'
                onClick={() => {
                  updateImage('')
                  setPreview(null)
                }}
              >
                <i className='fa-solid fa-xmark'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </span>
            </div>

            {/* <div className='form-text'>
              {intl.formatMessage({id: 'KIDLING.HOME.ALLOWED_FILE_TYPES'})}: png, jpg, jpeg.
            </div> */}
          </div>
          <div className='mx-8 d-flex flex-column'>
            <h3 className='  '>{currentUser?.usersName}</h3>
            <label className='label  form-label fs-6 fw-semibold text-muted'>
              {currentUser?.email}
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserTopProfile
