import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getEmployeeInfo, getUserInfo} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import PageLoader from '../../../general-components/pageLoader/PageLoader'
import {groupBy} from '../../../utils/common'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    localStorage.removeItem('kidling-status')
    localStorage.removeItem('kidling-class')
    // localStorage.removeItem('MasterToken')
    localStorage.removeItem('kidling-selectedCompany')
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const {setMobileConfiguration} = useAppContext()
  // We should request user by authToken (IN OUR EXAMPLE IT'S TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (id: string, apiToken: string) => {
      try {
        if (!didRequest.current) {
          const auth: any = authHelper.getAuth()
          const resp1 = groupBy(auth?.configurtations, 'mobileConfigurationName')
          if (resp1) setMobileConfiguration(resp1)
          if (auth) {
            authHelper.setAuth(auth)
          }
          const {data: user} = await getUserInfo(id, apiToken)
          const employee = await getEmployeeInfo(id, apiToken)

          setCurrentUser({...user, employee})
          setShowSplashScreen(false)
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.token) {
      requestUser(auth.userId, auth.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <PageLoader /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
