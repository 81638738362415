import {useIntl} from 'react-intl'

const UsersListLoading = () => {
  const intl = useIntl()
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  return (
    <div
      style={{...styles, position: 'absolute', textAlign: 'center', zIndex: 100000}}
      className='d-flex flex-column '
    >
      <i className='fa-solid fa-spinner fa-spin'></i>
      <p className='mt-4'>{intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})} ...</p>
    </div>
  )
}

export {UsersListLoading}
