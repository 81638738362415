import {useEffect, useState} from 'react'
import {getAllKids, getClassesByEmployee} from '../../actions/kids'
import {useAuth} from '../modules/auth'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useParams} from 'react-router-dom'

const useKids = () => {
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const {currentCompany} = useAppContext()
  const [data, setData] = useState([])
  const [menuData, setMenuData] = useState<any>([])
  const [showKids, setShowKids] = useState(false)
  const [classes, setClasses] = useState([])
  const {classesId} = useParams()
  useEffect(() => {
    if (classes?.length > 0) {
      let temp: any = []
      classes?.map((singleClass: any) =>
        temp.push({
          url: `kids/${singleClass.classesId}`,
          title: singleClass.classesName,
          count: '',
          show: true,
        })
      )
      setMenuData(temp)
    }
  }, [classes])

  const readKids = async (search?: string) => {
    if (!!search) {
      setShowKids(true)
    } else {
      setShowKids(false)
    }

    setIsLoading(true)
    setData([])
    let filter = classesId ? `Classes.any(x => x.classesId = ${classesId})` : ``
    if (!!search) {
      if (filter !== '') filter += ' AND '
      filter += `kidName.toLower().contains("${search.toLowerCase().trim()}")`
    }

    const resp = await getAllKids({
      filter: filter,
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    setData(resp)
    setIsLoading(false)
  }

  const readAllKids = async () => {
    setIsLoading(true)
    setData([])

    const resp = await getAllKids({
      filter: '1=1',
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    setData(resp)
    setIsLoading(false)
  }
  const readClasses = async () => {
    setIsLoading(true)
    const resp = await getClassesByEmployee()

    setClasses(resp)
    setIsLoading(false)
  }
  return {isLoading, data, menuData, showKids, readKids, readAllKids, readClasses, classes}
}
export default useKids
