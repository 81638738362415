import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getAllCountries = async () => {
  return axios
    .get(`${endpoints.COUNTRY}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllCities = async (filter: string) => {
  return axios
    .get(`${endpoints.CITY}/1=1`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getGenders = async () => {
  return axios
    .get(`${endpoints.GENDER}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getDepartments = async () => {
  return axios
    .get(`${endpoints.DEPARTMENT}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getNationalities = async () => {
  return axios
    .get(`${endpoints.NATIONALITY}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getPositions = async () => {
  return axios
    .get(`${endpoints.POSITION}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getStates = async () => {
  return axios
    .get(`${endpoints.STATE}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getEmployeeTypes = async () => {
  return axios
    .get(`${endpoints.EMPLOYEE_TYPE}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getCostCenter = async () => {
  return axios
    .get(`${endpoints.COST_CENTER}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getAttendancetype = async () => {
  return axios
    .get(`${endpoints.ATTENDANCE_TYPE}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}
