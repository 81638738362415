/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import ProfileSidebar from './components/ProfileSidebar'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import UserTopProfile from './components/UserTopProfile'
import UserInfo from './components/UserInfo'
import EmployeeInfo from './components/EmployeeInfo'
import UserAddress from './components/UserAddress'
import {useAuth} from '../../auth'
import {getEmployeeInfoByUserId} from '../../../../actions/employee'
import {getAllCities, getAllCountries} from '../../../../actions/common'
import ChangePassword from './components/ChangePassword'
import NotificationSettings from './components/NotificationSettings'
function Profile() {
  const [currentTab, setCurrentTab] = useState(0)
  const {setEmployeeInfo, setCountries, setCities} = useAppContext()
  const {currentUser}: any = useAuth()

  useEffect(() => {
    if (currentUser) getEmployeeInfo()
  }, [currentUser])

  useEffect(() => {
    const readCountries = async () => {
      const resp = await getAllCountries()
      setCountries(resp)
    }
    readCountries()
  }, [])

  useEffect(() => {
    const readCities = async () => {
      const resp = await getAllCities('1=1')
      setCities(resp)
    }
    readCities()
  }, [])

  const getEmployeeInfo = async () => {
    const resp = await getEmployeeInfoByUserId(currentUser?.usersId || 0)
    if (resp) setEmployeeInfo(resp)
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      <div className='container-xxl mb-7'>
        <UserTopProfile />
      </div>

      <div id='kt_app_content_container' className='app-container container-xxl'>
        <div className='d-flex flex-column flex-lg-row'>
          <div className='  d-flex flex-column flex-lg-row-auto w-100 w-lg-275px'>
            <div className='card card-flush mb-0'>
              <ProfileSidebar setCurrentTab={setCurrentTab} currentTab={currentTab} />
            </div>
          </div>
          <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
            <div className='card'>
              {currentTab === 0 && (
                <div className='p-8'>
                  <UserInfo />
                </div>
              )}
              {currentTab === 1 && (
                <div className='p-8'>
                  <EmployeeInfo />
                </div>
              )}
              {currentTab === 2 && (
                <div className='p-8'>
                  <UserAddress />
                </div>
              )}
              {currentTab === 3 && (
                <div className='p-8'>
                  <ChangePassword />
                </div>
              )}
              {currentTab === 4 && (
                <div className='p-8'>
                  <NotificationSettings />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
