import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, ReactElement, useState} from 'react'
import {useIntl} from 'react-intl'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import * as Yup from 'yup'
import SweetAlert from 'react-bootstrap-sweetalert'
import {useAuth} from '../../../../../../auth'
import {UsersListLoading} from '../../../../../../../general-components/loading/UsersListLoading'
import {getAllKids} from '../../../../../../../../actions/kids'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import {useAppContext} from '../../../../../../../../_metronic/layout/core/AppContextProvider'
import {BulkCheckout} from '../../../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import ResponsiveDatePicker from '../../../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'

interface Props {
  showModal: {kidId: number | null; show: boolean}
  setShowModal: any
}
const CheckoutDialog: FC<Props> = ({showModal, setShowModal}): ReactElement => {
  const intl = useIntl()
  const {currentCompany, setKidsList} = useAppContext()
  const [showSuccess, setShowSuccess] = useState(false)
  const {currentUser} = useAuth()
  const {classesId} = useParams()
  const [userForEdit] = useState({
    contact: '',
    date: new Date(),
  })

  const kidSchema = Yup.object().shape({
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values: any, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          kidAttendanceIds: [showModal.kidId],
          employeeId: currentUser?.employee?.employeeId,
          dateTimeOut: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
          contactRemarks: values.contact,
          companyId: currentCompany?.value,
          mobileConfigurationId: 0,
        }

        const resp = await BulkCheckout(data)

        if (!!resp) {
          const resp = await getAllKids({
            filter: classesId ? `Classes.any(x => x.classesId = ${classesId})` : '1=1',
            employeeId: currentUser?.employee?.employeeId,
            companyId: currentCompany?.value,
          })
          setKidsList(resp)
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          close()
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex: any) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })
  const close = () => {
    formik.resetForm()
    setShowModal({kidId: null, show: false})
  }

  return (
    <Modal isOpen={showModal.show} toggle={close}>
      <ModalHeader toggle={close}>{intl.formatMessage({id: 'KIDLING.HOME.CHECKOUT'})}</ModalHeader>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <ModalBody>
          <>
            {/* begin::Scroll */}
            <div
              className='d-flex flex-column scroll-y overflow-auto me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              <div className='fv-row my-7'>
                <label className='label  form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.DATETIME'})}
                </label>
                <div
                  className={clsx(
                    {'is-invalid': formik.touched.date && formik.errors.date},
                    {
                      'is-valid': formik.touched.date && !formik.errors.date,
                    }
                  )}
                >
                  <ResponsiveDatePicker
                    dateTime
                    onAccept={(val: any) => formik.setFieldValue('date', val)}
                    value={formik.values.date}
                  />
                </div>
              </div>

              {/* <div className='fv-row mb-7'>
                <label className='  form-label fs-6 fw-semibold text-muted'>
                  {' '}
                  {intl.formatMessage({id: 'KIDLING.HOME.CONTACT_REMARKS'})}
                </label>
                <textarea
                  {...formik.getFieldProps('contact')}
                  className={clsx(
                    'form-control   mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.contact && formik.errors.contact},
                    {
                      'is-valid': formik.touched.contact && !formik.errors.contact,
                    }
                  )}
                  name='contact'
                  autoComplete='off'
                  rows={1}
                />
                {formik.touched.contact && formik.errors.contact && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.contact}</span>
                    </div>
                  </div>
                )}
              </div> */}
            </div>
            {/* end::Scroll */}

            {/* begin::Actions */}

            {/* end::Actions */}
          </>
        </ModalBody>

        <ModalFooter>
          <div className='text-center '>
            <button
              type='reset'
              onClick={() => close()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
            >
              {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              </span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </ModalFooter>
        {formik.isSubmitting && <UsersListLoading />}
      </form>

      <SweetAlert
        show={showSuccess}
        success
        title='Success!'
        onConfirm={(e) => setShowSuccess(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
      {/* <SweetAlert
        show={showErrorMessage}
        error
        title={<ErrorText text='The Label Already Exists!' />}
        onConfirm={(e) => setShowErrorMessage(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      /> */}
    </Modal>
  )
}

export default CheckoutDialog
