/* eslint-disable react-hooks/exhaustive-deps */
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import HrCard from '../../../../../general-components/hr-card'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import {KidSearchComponent} from '../seach-kids/KidSearchComponent'
import KidsList from '../kids-page/kids-list'
import CustomTabs from '../../../../../general-components/custom-tabs'
import {NoRecords} from '../../../../../general-components/NoRecords'
import useKids from '../../../../../hooks/useKids'
import {useEffect} from 'react'
function ClassPage() {
  const intl = useIntl()
  const navigate = useNavigate()
  const company = window.location.pathname.split('/')[1]
  const {groupTab, setGroupTab} = useAppContext()
  const {isLoading, data, menuData, showKids, classes, readKids, readClasses, readAllKids} =
    useKids()

  useEffect(() => {
    readClasses()
  }, [])

  useEffect(() => {
    if (groupTab === 1) {
      readAllKids()
    }
  }, [groupTab])

  return (
    <div className='d-flex flex-column  position-relative card-body'>
      <button
        className='btn  position-absolute '
        style={{top: '1rem', left: '0rem', width: '2rem'}}
        onClick={() => navigate(company ? `/${company}/terminal/main` : `/kidling/terminal/main`)}
      >
        <i className='fa-solid fa-arrow-left-long' style={{fontSize: '1.5rem'}}></i>
      </button>
      <div className='mt-16 w-md-50 w-sm-75 w-100 mx-sm-auto'>
        <KidSearchComponent filterData={readKids} />
      </div>

      {!showKids && (
        <>
          <div className='row p-4 p-sm-8  g-6 h-50 mt-2   w-100 mx-auto'>
            <CustomTabs
              value={groupTab}
              onChange={(val: any) => {
                setGroupTab(val)
              }}
              options={[
                intl.formatMessage({id: 'KIDLING.HOME.CLASS'}),
                intl.formatMessage({id: 'KIDLING.MESSAGE.KIDS'}),
              ]}
            />
            {groupTab === 0 ? (
              <>
                {menuData.map((item: any, index: number) => (
                  <div className='col-6  ' key={index}>
                    <HrCard {...item} />
                  </div>
                ))}
                {!isLoading && classes?.length === 0 && <NoRecords />}
              </>
            ) : (
              <KidsList isLoading={isLoading} data={data} />
            )}
          </div>
          {isLoading && <UsersListLoading />}
        </>
      )}
      {showKids && <KidsList isLoading={isLoading} data={data} />}
    </div>
  )
}

export default ClassPage
