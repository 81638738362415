import {FC, ReactElement, useEffect, useState} from 'react'
import {KidStatusCssClasses} from '../../../../../../general-components/status-formatter/StatusColumnFormatter'
import {useIntl} from 'react-intl'
import AvatarWithLetters from '../../../../../../general-components/avatar-with-letters'
import {NoRecords} from '../../../../../../general-components/NoRecords'
import {UsersListLoading} from '../../../../../../general-components/loading/UsersListLoading'
import CheckoutDialog from '../components/employee-check-out-modal/CheckoutDialog'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../../../../../../general-components/error-text/ErrorText'
import {getAttendancetype} from '../../../../../../../actions/common'

interface Props {
  data: any
  isLoading: boolean
  readEmployees: any
}
const EmployeesList: FC<Props> = ({data, isLoading, readEmployees}): ReactElement => {
  const intl = useIntl()
  const [attendanceTypes, setAttendanceTypes] = useState([])

  const [showCheckout, setShowCheckout] = useState({
    employeeAttendanceId: null,
    employeeId: null,
    show: false,
    data: {},
  })

  const [showError, setShowError] = useState(false)

  useEffect(() => {
    const read = async () => {
      const attendancetype = await getAttendancetype()
      setAttendanceTypes(attendancetype)
    }
    read()
  }, [])

  return (
    <div>
      <div className='row p-8 g-6   mt-16  w-100 justify-content-sm-center '>
        {data?.map((employee: any, index: number) => {
          const openRecord = employee?.attendance?.find((i: any) => i?.out === null)
          return (
            <div className='col-6 col-sm-4 col-md-3' key={index}>
              <div className='d-flex align-items-center justify-content-center flex-column'>
                <div
                  className={`cursor-pointer w-155px h-155px rounded-circle border border-5 border-${
                    KidStatusCssClasses[
                      employee?.attendance?.length > 0
                        ? openRecord && openRecord?.attendanceTypeId === 2
                          ? 0
                          : openRecord?.attendanceTypeId === 1
                          ? 1
                          : 3
                        : 2
                    ]
                  } d-flex align-items-center justify-content-center flex-column`}
                  onClick={() => {
                    setShowCheckout({
                      employeeId: employee?.employeeId,
                      employeeAttendanceId: openRecord?.employeeAttendanceId,
                      show: true,
                      data: openRecord,
                    })
                  }}
                >
                  <AvatarWithLetters
                    image={employee.imagePath}
                    alt={`${employee.label.charAt(0).toUpperCase()}`}
                    large
                    rounded
                  />
                </div>
                <p className='my-2'>{employee.label}</p>
              </div>
            </div>
          )
        })}
      </div>
      {!isLoading && data?.length === 0 && <NoRecords />}
      {isLoading && <UsersListLoading />}

      <CheckoutDialog
        showModal={showCheckout}
        setShowModal={setShowCheckout}
        readEmployees={readEmployees}
        attendanceTypes={attendanceTypes}
      />
      <SweetAlert
        show={showError}
        error
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.KID_IS_ABSENT'})} />}
        onConfirm={(e) => setShowError(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </div>
  )
}

export default EmployeesList

// <div className='row mt-0  w-100 '>
// {employee.attendance?.map((item: any, index: number) => (
//   <div key={index} className='col-8 mx-auto'>
//     <div className='row'>
//       <div className='col-1'>
//         {/* <i className='bi bi-clock-fill me-2 text-primary'></i> */}
//         <i
//           className={`${
//             item.attendanceTypeId === 1
//               ? 'fa-regular fa-clock text-success'
//               : 'fa-solid fa-mug-hot text-danger'
//           }`}
//         ></i>
//       </div>
//       <div className='col-4 pe-0'>
//         <span className=' fs-7'>
//           {item.in ? moment(item.in).format('HH:mm') : '--:--'}
//         </span>
//       </div>
//       <div className='col-1 ps-0 pe-2'>
//         <span className=' '>
//           <i className='fa-solid fa-angles-right'></i>
//         </span>
//       </div>
//       <div className='col-4 pe-0'>
//         <span className=' fs-7'>
//           {item.out ? moment(item.out).format('HH:mm') : '--:--'}
//         </span>
//       </div>
//     </div>
//   </div>
// ))}
// </div>
