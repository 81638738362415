import axios from 'axios'
import {WHITE_LABEL} from '../config/endpoints'

export const getWhiteLabel = async (filter: string) => {
  return axios
    .get(`${WHITE_LABEL}/listwhere/${filter}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
