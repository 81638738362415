const API_URL = process.env.REACT_APP_API_URL
export const LOGIN_ADMIN = `${API_URL}/authentication/loginApp`
export const LOGIN = `authentication/login`
export const EDUCATOR_LOGIN = `/authentication/educator/login`
export const USERS = `users`
export const LANGUAGE = `${API_URL}/language/listwhere/1=1`
export const TRANSLATIONS = `${API_URL}/mobile/GetTranslationByLanguageId`
export const CHANGE_PASSWORD = `mobile/ChangePassword`
export const SEND_OTP_BY_EMAIL = `${API_URL}/authentication/SendOTPMaster`
export const CHANGE_PASSWORD_BY_OTP = `${API_URL}/authentication/ChangePasswordOTPMaster`
///kids
export const KIDS = `mobile/GetAllKids`
export const STATUSES = `contractstatus`
export const CLASSES = `classes`
export const ABSENT_REASONS = `kidabsentreason`
export const ACTIVITES = `activity`
export const MOOD = `mood`
export const REQUEST_ITEMS = `requestitem`
export const EVENTS = `events`
export const EVENTS_WITH_RECURRENTS = `mobile/GetEventDetails`
export const ROOMS = `room`
export const SLEEP_HABITS = `sleephabit`
export const EVENTS_SCHEDULE = `eventsschedule`
export const CLASSES_WITH_EXPRESSION = `mobile/GetClasses`
export const EVENTS_CATEGORY = `eventscategory`
export const EVENTS_KID = `eventsKid`
export const EVENTS_CONTACT = `eventscontact`

//Bulk
export const BULK_ATTENDANCE = `kidattendance/listByKidIds`
export const BULK_CHECKOUT = `kidattendance/listByKidAttendanceIds`
export const BULK_ABSENCE = `kidabsent/listByKidIds`
export const BULK_ACTIVITY = `kidactivity/listByKidIds`
export const BULK_MEAL = `kidmealtime/listByKidIds`
export const BULK_SLEEP = `kidsleeptime/listByKidIds`
export const BULK_DRINK = `kiddrink/listByKidIds`
export const BULK_PROTOCOL = `kidprotocolremark/listByKidIds`
export const BULK_MOOD = `kidmood/listByKidIds`
export const BULK_REQUEST = `requestkid/listByKidIds`
export const BULK_EVENT = `eventskid/listByKidIds`
export const BULK_MEDICAL = `kidmedicalrecord/listByKidIds`
export const BULK_MEDIA = `mobile/AddKidMediaFile`
export const BULK_EVENT_CONTACT = `eventscontact/listByContactIds`
export const DEREGISTRATION = `kidmealdailyderegistration`

//Menu pages
export const KID_ATTENDACE = `mobile/GetKidAttendances`
export const KID_ABSENSE = `mobile/GetKidAbsences`
export const KID_ACTIVITY_LIST = `mobile/GetKidsActivityByClassId`
export const KID_MEALS = `mobile/GetKidsMealTime`
export const KID_SLEEP_TIMES = `mobile/GetKidsSleeps`
export const KID_DRINKS_LIST = `mobile/GetKidsDrinks`
export const KID_PROTOCOLS_LIST = `mobile/GetKidsProtocols`
export const KID_REQUESTS_LIST = `mobile/GetRequestsKid`
export const KID_SERVICES_LIST = `mobile/GetKidServices`
export const KID_MEDICAL_RECORDS = `mobile/GetKidMedicalRecords`

//Meal
export const MEALTIME = `mealtime`
export const MEAL_PORTION = `mealportion`
export const FOOD = `fooditem`
export const KID_MEALTIME = `kidmealtime`
export const MEAL_DEREGISTRATION = `mobile/GetKidsDeregistrations`

//activity
export const KID_ACTIVITY = `kidactivity`

//sleeps
export const KID_SLEEPTIME = `kidsleeptime`

//drinks
export const KID_DRINKS = `kiddrink`

//protocol
export const KID_PROTOCOL = `kidprotocolremark`

//Requests
export const KID_REQUEST = `requestkid`

//service
export const KID_SERVICE = `kidservice`

//location
export const ATTENDANCE_LOCATION = `attendancelocation`

//translations
export const LABEL = `${API_URL}/label`
export const TRANSLATION = `${API_URL}/translation`

//image
export const READ_FILE = `authentication/ReadFile`

//attendance
export const ATTENDACE = `kidattendance`

//absent
export const ABSENCE = `kidabsent`

//company
export const COMPANY = `company`
export const COMPANY_INFO = `companyinfo`

//messages
export const SEND_MESSAGE = `mobile/AddNotification`
export const GET_MESSAGES = `mobile/GetNotificationsByUsersId`
export const UPDATE_RECEIVED_MESSAGE = `mobile/UpdateReceivedNotifications`
export const UPDATE_SENT_MESSAGE = `mobile/UpdateSentNotifications`
export const GET_SUB_MESSAGES = `mobile/GetAllSubMessages`
export const GET_MESSAGE = `notification/listwhere`
export const NEW_MESSAGES_COUNT = `mobile/GetUnreadMessagesCountByUserId/UsersId`

//medical
export const MEDICAL_TYPES = `medicalrecordtype`
export const MEDICAL_RECORD = `kidmedicalrecord`

//contact
export const CONTACT = `contact`
export const KID_CONTACTS = `mobile/GetKidContacts`

//white label
export const WHITE_LABEL = `${API_URL}/whitelabel`

//country
export const COUNTRY = `country`

//city
export const CITY = `city/listwhere`

//state
export const STATE = `state`

//Gender
export const GENDER = `gender`

//Department
export const DEPARTMENT = `department`

//Costcenter
export const COST_CENTER = `costcenter`

//Attendancetype
export const ATTENDANCE_TYPE = `attendancetype`

//Nationality
export const NATIONALITY = `nationality`

//position
export const POSITION = `position`

//deregisteration
export const KID_DEREGISTRATION = `kidmealdailyderegistration`

//holiday
export const HOLIDAY = `holiday`

//dayOff
export const DAYOFF = `dayoff`

//Assessments
export const ASSESSMENT = `assessment`
export const ASSESSMENT_CATEGORY = `assessmentcategory`
export const ASSESSMENT_ITEM = `assessmentitem`
export const ASSESSMENT_ITEM_RATE = `assessmentitemrate`
export const ASSESSMENT_RATE_METHOD = `assessmentratemethod`
export const ASSESSMENT_SUBCATEGORY = `assessmentsubcategory`
export const KID_ASSESSMENT = `kidassessment`
export const GET_KIDS_ASSESSMENTS = `mobile/GetKidassessments`

//employee
export const EMPLOYEE = `employee`
export const EMPLOYEE_DEEP_UPDATE = `employee/deep-update`
export const EMPLOYEE_TYPE = `employeetype`
export const EMPLOYEE_ATTENDANCE = `employeeattendance`
export const EMPLOYEE_SHIFT = `employeeshift`

//media
export const KIDMEDIA = `kidmediafile`
export const KIDMEDIA_BY_EMPLOYEE = `kidmediafile/employee-pagination-list`
//mobileconfiguration
export const MOBILE_CONFIGURATION = `mobileconfiguration`
export const PARENT_PINBOARD = `pinboard`

export const EMPLOYEE_CHECKIN = `mobile/TermenalEmployeeCheckIn`
