/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import {KidSearchComponent} from '../seach-kids/KidSearchComponent'
import EmployeesList from './employees-list'
import useEmployee from '../../../../../hooks/useEmployee'
import {useAuth} from '../../../../auth'

const EmployeesPage: FC = (): ReactElement => {
  const {auth} = useAuth()
  const navigate = useNavigate()
  const company = window.location.pathname.split('/')[1]
  const {isLoading, readEmployees, currentSelectedCompany, setCurrentSelectedCompany, data} =
    useEmployee()

  useEffect(() => {
    if (!isLoading) {
      readEmployees('')
    }
  }, [currentSelectedCompany])

  return (
    <div className='d-flex flex-column  position-relative'>
      <button
        className='btn  position-absolute '
        style={{top: '1rem', left: '0rem', width: '1rem'}}
        onClick={() => navigate(company ? `/${company}/terminal` : `/kidling/terminal`)}
      >
        <i className='fa-solid fa-arrow-left-long' style={{fontSize: '1.5rem'}}></i>
      </button>
      <div className='mt-16 w-md-50 w-sm-75 w-100 mx-sm-auto'>
        <div className=' d-flex align-items-center justify-content-center w-100'>
          {auth?.companies?.length > 1 &&
            auth?.companies?.map((item: any, index: number) => (
              <div
                className={`btn btn-outline  btn-outline-dashed btn-active-light-primary  text-start pt-8 px-12 pb-2 m-2  d-flex align-items-center flex-column position-relative ${
                  currentSelectedCompany?.id === item.id && 'active'
                } `}
                data-kt-button='true'
                key={index}
                onClick={() => {
                  setCurrentSelectedCompany(item)
                }}
              >
                <AvatarWithLetters
                  verysmall
                  image={item?.image}
                  alt={item?.name?.charAt(0)?.toUpperCase()}
                  objectContain
                />
                <input
                  className={`form-check-input  position-absolute top-0 start-0 m-2`}
                  type='radio'
                  name='childFilter'
                  checked={currentSelectedCompany?.id === item.id}
                />

                <div className='form-check form-check-custom form-check-solid form-check-sm d-flex align-items-center   '>
                  <div className='fs-4 fw-bold text-gray-800  '>{item.name}</div>
                </div>
              </div>
            ))}
        </div>
        <KidSearchComponent filterData={readEmployees} />
      </div>
      <EmployeesList isLoading={isLoading} data={data} readEmployees={readEmployees} />
    </div>
  )
}

export default EmployeesPage
