/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'
import {useAppContext} from '../../../layout/core/AppContextProvider'
import {useIntl} from 'react-intl'

const Languages: FC = () => {
  const lang = useLang()
  const {languages}: any = useAppContext()
  const [currentLanguage, setCurrentLanguage] = useState<any>(null)
  const intl = useIntl()

  useEffect(() => {
    if (languages?.length > 0) {
      const temp = languages?.find((x: any) => x.value === lang)
      setCurrentLanguage(temp)
    }
  }, [languages])

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          {intl.formatMessage({id: 'KIDLING.HOME.LANGUAGE'})}

          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.label}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='Kidlings'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages?.map((l: any) => (
          <div
            className='menu-item px-3'
            key={l.value}
            onClick={() => {
              setLanguage(l.value)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {
                active: l.value === currentLanguage?.value,
              })}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='Kidlings' />
              </span>
              {l.label}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
