import {useIntl} from 'react-intl'
import {Button} from 'reactstrap'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import {updateEmployeeInfo} from '../../../../../actions/employee'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'

function NotificationSettings() {
  const intl = useIntl()
  const {employeeInfo, setEmployeeInfo} = useAppContext()
  const [initialValues, setInitialValues] = useState<any>({
    employeeReceiveCommunicationFromEmployees:
      employeeInfo?.employeeReceiveCommunicationFromEmployees,
    employeeReceiveCommunicationFromParents: employeeInfo?.employeeReceiveCommunicationFromParents,
  })
  useEffect(() => {
    if (employeeInfo)
      setInitialValues({
        employeeReceiveCommunicationFromEmployees:
          employeeInfo?.employeeReceiveCommunicationFromEmployees,
        employeeReceiveCommunicationFromParents:
          employeeInfo?.employeeReceiveCommunicationFromParents,
      })
  }, [employeeInfo])

  const profileDetailsSchema = Yup.object().shape({})

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: profileDetailsSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          ...employeeInfo,
          ...values,
        }

        const resp = await updateEmployeeInfo(data)

        if (!!resp.employeeId) {
          setEmployeeInfo(resp)

          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
        setSubmitting(false)
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_account_notifications' className='collapse show'>
        <form className='form' onSubmit={formik.handleSubmit} noValidate>
          <div className='card-body  pt-3 pb-4'>
            <div className='card mb-5 mb-xl-10'>
              <div className='row  mt-8 w-100'>
                <p className='col-6  '>
                  {' '}
                  {intl.formatMessage({
                    id: 'KIDLING.employeeReceiveCommunicationFromEmployees',
                  })}{' '}
                </p>
                <label className='form-check form-switch form-check-custom form-check-solid mb-2 col-6 justify-content-end'>
                  <input
                    className='form-check-input w-30px h-20px  '
                    type='checkbox'
                    name='employeeReceiveCommunicationFromEmployees'
                    checked={formik.values.employeeReceiveCommunicationFromEmployees}
                    onChange={() =>
                      formik.setFieldValue(
                        'employeeReceiveCommunicationFromEmployees',
                        !formik.values.employeeReceiveCommunicationFromEmployees
                      )
                    }
                  />
                </label>
              </div>
              <div className='row  mt-8 w-100'>
                <p className='col-6  '>
                  {' '}
                  {intl.formatMessage({
                    id: 'KIDLING.employeeReceiveCommunicationFromParents',
                  })}{' '}
                </p>
                <label className='form-check form-switch form-check-custom form-check-solid mb-2 col-6 justify-content-end'>
                  <input
                    className='form-check-input w-30px h-20px  '
                    type='checkbox'
                    name='employeeReceiveCommunicationFromParents'
                    checked={formik.values.employeeReceiveCommunicationFromParents}
                    onChange={() =>
                      formik.setFieldValue(
                        'employeeReceiveCommunicationFromParents',
                        !formik.values.employeeReceiveCommunicationFromParents
                      )
                    }
                  />
                </label>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Button color='primary' type='submit' disabled={formik.isSubmitting} className=' '>
              {!formik.isSubmitting &&
                intl.formatMessage({
                  id: 'AUTH.GENERAL.SUBMIT_BUTTON',
                })}
              {formik.isSubmitting && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NotificationSettings
