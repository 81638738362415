import React from 'react'
import HrCard from '../../../../../general-components/hr-card'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import moment from 'moment'
import './styles.scss'
const colors = ['danger', 'success', 'info', 'warning', 'secondary', 'light', 'primary']
function MainPage1() {
  const {pinboard} = useAppContext()
  const data = [
    {
      url: 'kidclass',
      icon: 'fa-solid fa-children',
      count: '',
      show: true,
    },
    {
      url: 'employees',
      icon: 'fa-solid fa-user-tie',
      count: '',
      show: true,
      stat: [],
    },
  ]

  return (
    <div className='div'>
      {pinboard.length > 0 && (
        <div className='d-flex flex-column w-100 p-4 p-sm-8 '>
          {pinboard.map((item: any, index: number) => (
            <div
              className={`alert alert-${colors[index % colors.length]}`}
              role='alert'
              key={index}
            >
              <div className='d-flex'>
                <i
                  className={`fa-solid fa-thumbtack me-2 fs-2 text-${
                    colors[index % colors.length]
                  }`}
                ></i>
                <div className='d-flex flex-column'>
                  <h5 className='my-0 py-0'>{item.pinboardName}</h5>
                  <p className='my-0 py-0 text-muted fs-7'>
                    {moment(item.pinboardDatetime).format('DD.MM.yyyy')}
                  </p>
                </div>
              </div>

              <p
                className='my-0 mt-1 py-0 text-muted fs-5'
                dangerouslySetInnerHTML={{__html: item.pinboardMessage}}
              ></p>
            </div>
          ))}
        </div>
      )}
      <div className='row p-4 p-sm-8  g-6 h-50 mt-4 w-sm-600px w-100 mx-auto '>
        {data.map((item: any, index: number) => (
          <div className='col-6  ' key={index}>
            <HrCard {...item} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default MainPage1
