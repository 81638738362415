/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import {useAppContext} from '../../core/AppContextProvider'
import {useThemeMode} from '../../../partials/layout/theme-mode/ThemeModeProvider'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config
  const {companyMetaData} = useAppContext()
  const {mode} = useThemeMode()

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch  w-100 ')}
      data-kt-sticky='true'
      data-kt-sticky-name='header'
      data-kt-sticky-offset="{default: '200px', lg: '300px' }"
      {...attributes.headerMenu}
      style={{
        backgroundColor:
          mode !== 'dark'
            ? companyMetaData.topBarColor
              ? companyMetaData.topBarColor
              : '#8d4290'
            : '#0e0e16',
      }}
    >
      <div className={clsx(classes.headerContainer.join(' '), 'd-flex align-items-center')}>
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG
                path='/media/icons/duotune/abstract/abs015.svg'
                className='svg-icon-2x mt-1  '
                color={
                  mode !== 'dark' && companyMetaData.iconColor ? companyMetaData.iconColor : ''
                }
              />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}

        {/* <div className='d-flex topbar align-items-center   ms-n2 me-3' title='Show aside menu'>
          <div
            className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/abstract/abs015.svg'
              className={`svg-icon-2x  mt-1 `}
              color={mode !== 'dark' && companyMetaData.iconColor ? companyMetaData.iconColor : ''}
            />
          </div>
        </div> */}

        <div className='header-logo me-5 me-md-10 flex-grow-1  '>
          <Link to='/'>
            <img
              alt='Logo'
              src={
                mode === 'dark'
                  ? companyMetaData?.imagePathLogoDark
                    ? companyMetaData?.imagePathLogoDark
                    : '/logo.png'
                  : companyMetaData?.imagePathLogoLight
                  ? companyMetaData?.imagePathLogoLight
                  : '/logo.png'
              }
              className='logo-default h-25px'
            />
            <img
              alt='Logo'
              src={
                mode === 'dark'
                  ? companyMetaData?.imagePathLogoDark
                    ? companyMetaData?.imagePathLogoDark
                    : '/logo.png'
                  : companyMetaData?.imagePathLogoLight
                  ? companyMetaData?.imagePathLogoLight
                  : '/logo.png'
              }
              className='logo-sticky h-25px'
            />
          </Link>
        </div>

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between  '>
          {/* begin::Navbar */}
          {/* {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )} */}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
