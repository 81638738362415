export const getBase64 = async (file: any, cb: any) => {
  const reader = new FileReader()

  reader.readAsDataURL(file)
  reader.onload = () => {
    return cb(reader.result)
  }
  reader.onerror = (error) => {
    console.log('Error: ', error)
  }
}

export const decodeBase64 = ({
  base64,
  name,
  type,
}: {
  base64: string
  name: string
  type: string
}): Promise<any> => fetch(base64).then((response) => response.blob())
