import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import {WithChildren} from '../helpers'
import {useAppContext} from '../layout/core/AppContextProvider'
import {Spinner} from 'react-bootstrap'

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const {translations}: any = useAppContext()

  if (!translations) return <Spinner animation={'border'} />
  return (
    <IntlProvider locale={locale} messages={translations} defaultLocale='de' onError={() => {}}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
