import moment from 'moment'
import {getAllEmployeesByKita, getEmployeeAttendance} from '../../actions/employee'
import {groupBy} from '../utils/common'
import {useState} from 'react'
import {useAuth} from '../modules/auth'

const useEmployee = () => {
  const {auth} = useAuth()
  const [currentSelectedCompany, setCurrentSelectedCompany] = useState({
    id: auth?.companies[0]?.id,
    name: auth?.companies[0].name,
    image: auth?.companies[0]?.image,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])

  const readEmployees = async (search?: string) => {
    try {
      setIsLoading(true)
      let filter = `companyId=${currentSelectedCompany?.id}`
      if (!!search) {
        if (filter !== '') filter += ' AND '
        filter += `employeeName.toLower().contains("${search.toLowerCase().trim()}")`
      }

      const resp1 = await getEmployeeAttendance(
        `date>="${moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')}"`
      )

      const groupedAttendance = groupBy(resp1, 'employeeId')

      const resp = await getAllEmployeesByKita(filter)

      let temp: any = []
      resp?.map(
        (item: any) =>
          item.active &&
          temp.push({
            value: item.employeeId,
            employeeId: item.employeeId,
            label: item.employeeName,
            email: item.email,
            imagePath: item.imagePath,
            usersId: item.usersId,
            companyId: item.companyId,
            attendance: groupedAttendance[item.employeeId],
          })
      )

      temp.sort((a: any, b: any) => a.label.localeCompare(b.label))

      setData(temp)
      setIsLoading(false)
    } catch (e) {}
  }
  return {readEmployees, isLoading, data, currentSelectedCompany, setCurrentSelectedCompany}
}
export default useEmployee
