import React from 'react'
import {useIntl} from 'react-intl'
import ReactSelect from 'react-select'

const SelectWithAllOption = (props: any) => {
  const intl = useIntl()
  const initialOptions = props.options
  const allOption = {
    label: intl.formatMessage({id: 'COMMON.SELECT_ALL'}),
    value: '*',
  }

  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        menuPosition='fixed'
        options={initialOptions.length > 0 ? [allOption, ...initialOptions] : []}
        onChange={(selected: any, event: any) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === allOption.value) {
              return props.onChange([...initialOptions])
            }
            let result = []
            if (selected.length === initialOptions.length) {
              if (selected.includes(allOption)) {
                result = selected.filter((option: any) => option.value !== allOption.value)
              } else if (event.action === 'select-option') {
                result = [...initialOptions]
              }

              return props.onChange(result)
            }
          }

          return props.onChange(selected)
        }}
      />
    )
  }
  return <ReactSelect {...props} menuPosition='fixed' />
}

export default SelectWithAllOption
