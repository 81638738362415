/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {KidSearchComponent} from '../seach-kids/KidSearchComponent'
import KidsList from './kids-list'
import useKids from '../../../../../hooks/useKids'

const KidsPage: FC = (): ReactElement => {
  const navigate = useNavigate()
  const company = window.location.pathname.split('/')[1]
  const {classesId} = useParams()
  const {readKids, data, isLoading} = useKids()

  useEffect(() => {
    readKids()
  }, [classesId])

  return (
    <div className='d-flex flex-column  position-relative'>
      <button
        className='btn  position-absolute '
        style={{top: '1rem', left: '0rem', width: '1rem'}}
        onClick={() =>
          navigate(company ? `/${company}/terminal/kidclass` : `/kidling/terminal/kidclass`)
        }
      >
        <i className='fa-solid fa-arrow-left-long' style={{fontSize: '1.5rem'}}></i>
      </button>
      <div className='mt-16 w-md-50 w-sm-75 w-100 mx-sm-auto'>
        <KidSearchComponent filterData={readKids} />
      </div>
      <KidsList isLoading={isLoading} data={data} />
    </div>
  )
}

export default KidsPage
