import * as endpoints from '../config/endpoints'
import axios from 'axios'

export const BulkAttendace = async (data: any) => {
  return axios
    .post(endpoints.BULK_ATTENDANCE, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkCheckout = async (data: any) => {
  return axios
    .put(endpoints.BULK_CHECKOUT, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkAbsence = async (data: any) => {
  return axios
    .post(endpoints.BULK_ABSENCE, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkActivity = async (data: any) => {
  return axios
    .post(endpoints.BULK_ACTIVITY, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkMeal = async (data: any) => {
  return axios
    .post(endpoints.BULK_MEAL, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkSleep = async (data: any) => {
  return axios
    .post(endpoints.BULK_SLEEP, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkEditSleep = async (data: any) => {
  return axios
    .put(endpoints.BULK_SLEEP, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkDrink = async (data: any) => {
  return axios
    .post(endpoints.BULK_DRINK, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkProtocol = async (data: any) => {
  return axios
    .post(endpoints.BULK_PROTOCOL, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkMood = async (data: any) => {
  return axios
    .post(endpoints.BULK_MOOD, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkRequest = async (data: any) => {
  return axios
    .post(endpoints.BULK_REQUEST, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkEvent = async (data: any) => {
  return axios
    .post(endpoints.BULK_EVENT, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkMedical = async (data: any) => {
  return axios
    .post(endpoints.BULK_MEDICAL, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkMedia = async (data: any) => {
  return axios
    .post(endpoints.BULK_MEDIA, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkEventContact = async (data: any) => {
  return axios
    .post(endpoints.BULK_EVENT_CONTACT, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const KidDeRegistration = async (data: any) => {
  return axios
    .post(endpoints.DEREGISTRATION, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
