import {FC, ReactElement} from 'react'

interface Props {
  image?: string
  alt: string
  rounded?: boolean
  small?: boolean
  large?: boolean
  verysmall?: boolean
  extrasmall?: boolean
  objectContain?: boolean
}
const AvatarWithLetters: FC<Props> = ({
  image,
  alt,
  rounded,
  small,
  large,
  verysmall,
  extrasmall,
  objectContain,
}): ReactElement => {
  return (
    <div
      className={`rounded symbolmb-8 mb-sm-0 text-center ${
        large
          ? 'h-125px w-125px w-sm-150px h-sm-150px'
          : small
          ? 'w-100px h-100px w-md-60px h-md-60px'
          : verysmall
          ? 'w-40px h-40px'
          : extrasmall
          ? 'w-30px h-30px mb-0'
          : ' w-60px h-60px  w-md-100px h-md-100px '
      }  ${rounded && 'rounded-circle'} ${
        objectContain ? 'object-fit-contain' : 'object-fit-cover'
      }`}
    >
      {image ? (
        <img
          src={image}
          alt={alt}
          className={`rounded  bg-secondary text-primary  text-center ${
            large
              ? 'h-125px w-125px w-sm-150px h-sm-150px lh-sm-150px lh-125px '
              : small
              ? 'w-100px h-100px w-md-60px h-md-60px lh-100px  lh-md-60px '
              : verysmall
              ? 'w-40px h-40px  lh-40px'
              : extrasmall
              ? 'w-30px h-30px mb-0 lh-30px'
              : 'w-60px h-60px w-md-100px h-md-100px lh-60px lh-md-100px '
          }    mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow ${
            rounded && 'rounded-circle  '
          } ${objectContain ? 'object-fit-contain' : 'object-fit-cover'}`}
        />
      ) : (
        <div
          className={`rounded mb-8 bg-secondary text-primary symbol-label  ${
            large
              ? 'h-125px w-125px w-sm-150px h-sm-150px'
              : small
              ? 'w-100px h-100px w-md-60px h-md-60px'
              : verysmall
              ? 'w-40px h-40px'
              : extrasmall
              ? 'w-30px h-30px mb-0'
              : ' w-60px h-60px w-md-100px h-md-100px '
          }   mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black ${
            rounded && 'rounded-circle  '
          } ${objectContain ? 'object-fit-contain' : 'object-fit-cover'} `}
        >
          {alt}
        </div>
      )}
    </div>
  )
}

export default AvatarWithLetters
