import React from 'react'
import {useIntl} from 'react-intl'
export const KidStatusCssClasses = ['danger', 'success', 'secondary', 'info']
export const KidStatusCssClassesColors = ['white', 'white', 'black', 'white']
export const KidStatusTitles = [
  'KIDLING.HOME.ABSENSE',
  'KIDLING.HOME.PRESENT',
  'KIDLING.HOME.NO_STATUS',
  'KIDLING.HOME.CHECKED_OUT',
]

export function StatusColumnFormatter(status: number) {
  const intl = useIntl()
  return (
    <span className={`badge  bg-${KidStatusCssClasses[status]}  `}>
      {intl.formatMessage({id: KidStatusTitles[status]})}
    </span>
  )
}
