import {FC, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider, useLayout} from './core'
import {useLocation} from 'react-router-dom'
import {DrawerMessenger, ActivityDrawer, InviteUsers, UpgradePlan} from '../partials'
import {MenuComponent} from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import {WithChildren} from '../helpers'
import {themeModeSwitchHelper, useThemeMode} from '../partials/layout/theme-mode/ThemeModeProvider'
import {useAppContext} from './core/AppContextProvider'
import PageLoader from '../../app/general-components/pageLoader/PageLoader'

const MasterLayout: FC<WithChildren> = ({children}) => {
  const {classes} = useLayout()
  const {mode} = useThemeMode()
  const location = useLocation()
  const {isFinishedLoadingMetaData} = useAppContext()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    themeModeSwitchHelper(mode)
  }, [mode])
  if (!isFinishedLoadingMetaData) return <PageLoader />
  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          {/* <div
            className='position-absolute bg-primary h-350px w-100 start-0'
            style={{top: '5rem', zIndex: -1}}
          ></div> */}

          <HeaderWrapper />

          <div
            id='kt_content_container'
            className={clsx(
              'd-flex flex-column-fluid align-items-stretch px-0 px-4 mt-4 h-100',
              classes.contentContainer.join(' ')
            )}
          >
            <AsideDefault />

            <div className='wrapper d-flex flex-column flex-row-fluid  ' id='kt_wrapper'>
              <div className='flex-column-fluid' id='kt_content'>
                <div className='post' id='kt_post'>
                  <Content>
                    <Outlet />
                  </Content>
                </div>
              </div>
              {/* <Footer /> */}
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <RightToolbar /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
