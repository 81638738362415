import axios from 'axios'
import {LABEL, LANGUAGE, TRANSLATION, TRANSLATIONS} from '../config/endpoints'
import {toAbsoluteUrl} from '../_metronic/helpers'

export const allLanguages: any = [
  {
    languageId: 1,
    languageName: 'English',
    value: 'en',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    languageId: 2,
    languageName: 'Arabic',
    value: 'ar',
    flag: toAbsoluteUrl('/media/flags/saudi-arabia.svg'),
  },
  {
    languageId: 3,
    languageName: 'Deutsch',
    value: 'de',
    flag: toAbsoluteUrl('/media/flags/germany.svg'),
  },
  {
    languageId: 4,
    languageName: 'French',
    value: 'fr',
    flag: toAbsoluteUrl('/media/flags/france.svg'),
  },
]
export const getLanguages = async () => {
  const axiosInstance = axios.create()

  return axiosInstance
    .get(`${LANGUAGE}`)
    .then((response) => {
      const resp = response?.data

      const lang: any = []
      if (resp && resp.length > 0) {
        resp?.forEach((item: any) => {
          const language = allLanguages.filter(
            (i: any) => i.languageName.toLowerCase() === item.languageName.toLowerCase()
          )[0]
          lang.push({
            value: language.value,
            label: item.languageName,
            flag: language.flag,
            languageId: item.languageId,
          })
        })
      }
      return lang
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getTranslationsById = async (id: number) => {
  const axiosInstance = axios.create()
  // axiosInstance.interceptors.request.use(
  //   (config: any) => {
  //     config.headers['token'] = `${process.env.REACT_APP_LANGUAGE_API_KEY}`

  //     return config
  //   },
  //   (err: any) => Promise.reject(err)
  // )
  return axiosInstance
    .get(`${TRANSLATIONS}/${id}/controllerName="kidling"`)
    .then((resp) => resp.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const addNewLabel = async (data: any) => {
  const axiosInstance = axios.create()
  axiosInstance.interceptors.request.use(
    (config: any) => {
      config.headers['token'] = localStorage.getItem('MasterToken')

      return config
    },
    (err: any) => Promise.reject(err)
  )
  return axiosInstance
    .post(`${LABEL}`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const addNewTranslation = async (data: any) => {
  const axiosInstance = axios.create()
  axiosInstance.interceptors.request.use(
    (config: any) => {
      config.headers['token'] = localStorage.getItem('MasterToken')

      return config
    },
    (err: any) => Promise.reject(err)
  )
  return axiosInstance
    .post(`${TRANSLATION}`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getLabelByKey = async (key: string) => {
  const axiosInstance = axios.create()
  axiosInstance.interceptors.request.use(
    (config: any) => {
      config.headers['token'] = localStorage.getItem('MasterToken')

      return config
    },
    (err: any) => Promise.reject(err)
  )
  return axiosInstance
    .get(`${LABEL}/listwhere/controllerName="kidling"&labelKey="${key}"`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
