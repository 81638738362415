export const handleAddCheckBox = (arr: any, id: any) => {
  let temp: any = [...arr]
  const exists = temp.find((item: any) => item === id)

  if (exists === id) {
    temp = temp.filter((i: any) => i !== id)
  } else {
    temp.push(id)
  }
  return temp
}

export const groupBy = (items: any, key: any) =>
  items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  )
