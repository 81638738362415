import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, ReactElement, useState} from 'react'
import {useIntl} from 'react-intl'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import * as Yup from 'yup'
import {addNewLabel, addNewTranslation, getLabelByKey} from '../../../actions/language'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../error-text/ErrorText'

interface Props {
  showModal: boolean
  setShowModal: any
}
const TranslationDialog: FC<Props> = ({showModal, setShowModal}): ReactElement => {
  const intl = useIntl()
  const [showSuccess, setShowSuccess] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [eventInitialValues] = useState({
    label: '',
    controllerName: 'kidling',
    english: '',
    german: '',
    frensh: '',
    arabic: '',
  })

  const eventSchema = Yup.object().shape({
    label: Yup.string().required('Required'),
    controllerName: Yup.string(),
    english: Yup.string().required('Required'),
    german: Yup.string().required('Required'),
    frensh: Yup.string(),
    arabic: Yup.string(),
  })

  const formik = useFormik({
    initialValues: eventInitialValues,
    validationSchema: eventSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const resp1 = await getLabelByKey(values.label)
        if (resp1.length === 0) {
          const label = {
            labelName: values.label,
            labelKey: values.label,
            controllerName: values.controllerName,
          }
          const resp = await addNewLabel(label)
          if (resp) {
            if (!!values.english)
              await addNewTranslation({
                translationName: values.english,
                labelKey: values.label,
                languageId: 1,
              })
            if (!!values.arabic)
              await addNewTranslation({
                translationName: values.arabic,
                labelKey: values.label,
                languageId: 2,
              })
            if (!!values.german)
              await addNewTranslation({
                translationName: values.german,
                labelKey: values.label,
                languageId: 3,
              })
            if (!!values.frensh)
              await addNewTranslation({
                translationName: values.frensh,
                labelKey: values.label,
                languageId: 4,
              })

            setShowSuccess(true)
          }
          formik.resetForm()
        } else {
          setShowErrorMessage(true)
        }
        // close()
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
    }
  }

  const close = () => {
    formik.resetForm()
    setShowModal(false)
  }

  return (
    <Modal isOpen={showModal} toggle={close}>
      <ModalHeader toggle={close}>{intl.formatMessage({id: 'TRANSLATIONS'})}</ModalHeader>

      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        <ModalBody>
          <div className='fv-row mb-7'>
            <label className='label  form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'Label'})}
            </label>
            <input
              {...formik.getFieldProps('label')}
              className={clsx(
                'form-control   mb-3 mb-lg-0',
                {'is-invalid': formik.touched.label && formik.errors.label},
                {
                  'is-valid': formik.touched.label && !formik.errors.label,
                }
              )}
              value={formik.values.label}
              name='label'
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.label && formik.errors.label && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.label}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='label  form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'controllerName'})}
            </label>
            <input
              {...formik.getFieldProps('controllerName')}
              className={clsx(
                'form-control   mb-3 mb-lg-0',
                {'is-invalid': formik.touched.controllerName && formik.errors.controllerName},
                {
                  'is-valid': formik.touched.controllerName && !formik.errors.controllerName,
                }
              )}
              value={formik.values.controllerName}
              name='controllerName'
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.controllerName && formik.errors.controllerName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.controllerName}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='label  form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'English'})}
            </label>
            <input
              {...formik.getFieldProps('english')}
              className={clsx(
                'form-control   mb-3 mb-lg-0',
                {'is-invalid': formik.touched.english && formik.errors.english},
                {
                  'is-valid': formik.touched.english && !formik.errors.english,
                }
              )}
              value={formik.values.english}
              name='english'
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.english && formik.errors.english && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.english}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='label  form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'Arabic'})}
            </label>
            <input
              {...formik.getFieldProps('arabic')}
              className={clsx(
                'form-control   mb-3 mb-lg-0',
                {'is-invalid': formik.touched.arabic && formik.errors.arabic},
                {
                  'is-valid': formik.touched.arabic && !formik.errors.arabic,
                }
              )}
              value={formik.values.arabic}
              name='arabic'
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.arabic && formik.errors.arabic && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.arabic}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='label  form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'german'})}
            </label>
            <input
              {...formik.getFieldProps('german')}
              className={clsx(
                'form-control   mb-3 mb-lg-0',
                {'is-invalid': formik.touched.german && formik.errors.german},
                {
                  'is-valid': formik.touched.german && !formik.errors.german,
                }
              )}
              value={formik.values.german}
              name='german'
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.german && formik.errors.german && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.german}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='label  form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'Frensh'})}
            </label>
            <input
              {...formik.getFieldProps('frensh')}
              className={clsx(
                'form-control   mb-3 mb-lg-0',
                {'is-invalid': formik.touched.frensh && formik.errors.frensh},
                {
                  'is-valid': formik.touched.frensh && !formik.errors.frensh,
                }
              )}
              value={formik.values.frensh}
              name='frensh'
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.frensh && formik.errors.frensh && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.frensh}</span>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={close}>
            Cancel
          </Button>
          <Button color='primary' type='submit'>
            Add
          </Button>
        </ModalFooter>
      </form>
      <SweetAlert
        show={showSuccess}
        success
        title='Success!'
        onConfirm={(e) => setShowSuccess(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
      <SweetAlert
        show={showErrorMessage}
        error
        title={<ErrorText text='The Label Already Exists!' />}
        onConfirm={(e) => setShowErrorMessage(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </Modal>
  )
}

export default TranslationDialog
