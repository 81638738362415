/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {getPinboard} from '../../actions/pinboard'
import moment from 'moment'

const usePinboard = () => {
  const {setCurrentCompany, currentCompany, setPinboard} = useAppContext()
  useEffect(() => {
    if (!currentCompany) {
      const comp: any = localStorage.getItem('kidling-selectedCompany')
      if (comp) {
        setCurrentCompany(JSON.parse(comp))
      }
    }
  }, [])
  const readPinboard = async () => {
    const resp = await getPinboard(
      `pinboardShow=true&pinboardDatetime<="${moment()
        .endOf('day')
        .format('yyyy-MM-DDTHH:mm:ss')}"&companyId=${currentCompany.value}`
    )
    setPinboard(resp)
  }

  return {readPinboard}
}
export default usePinboard
