import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, ReactElement, useState} from 'react'
import {useIntl} from 'react-intl'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import * as Yup from 'yup'
import SweetAlert from 'react-bootstrap-sweetalert'
import {UsersListLoading} from '../../../../../../../general-components/loading/UsersListLoading'
import {toast} from 'react-toastify'
import {addEmployeeCheckin} from '../../../../../../../../actions/employee'
import {selectStyle, selectStyleDarkMode} from '../../../../../../../utils/select-style'
import SelectWithAllOption from '../../../../../../../general-components/select-with-all/SelectWithAllOption'
import {useThemeMode} from '../../../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import makeAnimated from 'react-select/animated'
import OtpInput from 'react-otp-input'
const animatedComponents = makeAnimated()

interface Props {
  showModal: {
    employeeAttendanceId: number | null
    employeeId: number | null
    show: boolean
    data: any
  }
  setShowModal: any
  readEmployees: any
  attendanceTypes: any
}
const CheckoutDialog: FC<Props> = ({
  showModal,
  setShowModal,
  readEmployees,
  attendanceTypes,
}): ReactElement => {
  const intl = useIntl()
  const [showSuccess, setShowSuccess] = useState(false)
  const [userForEdit] = useState({
    contact: '',
    otp: '',
    attendanceTypeId: null,
  })
  const {mode} = useThemeMode()
  const kidSchema = Yup.object().shape({
    remarks: Yup.string(),
    attendanceTypeId: Yup.mixed().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),

    otp: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
  })

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values: any, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          remarks: values.remarks,
          quickAccessCode: values.otp,
          attendanceTypeId: values.attendanceTypeId,
        }
        const resp = await addEmployeeCheckin(data)

        if (!!resp) {
          readEmployees()
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex: any) {
        console.error(ex)
      } finally {
        setSubmitting(false)
        close()
      }
    },
  })
  const close = () => {
    formik.resetForm()
    setShowModal({kidId: null, show: false})
  }

  return (
    <Modal isOpen={showModal.show} toggle={close}>
      <ModalHeader toggle={close}>{intl.formatMessage({id: 'KIDLING.HOME.CHECKOUT'})}</ModalHeader>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <ModalBody>
          <>
            {/* begin::Scroll */}
            <div
              className='d-flex flex-column scroll-y overflow-auto me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              <div className='fv-row my-7'>
                <label className='required form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.PASS_CODE'})}
                </label>
                <OtpInput
                  value={formik.values.otp}
                  onChange={(val: string) => formik.setFieldValue('otp', val)}
                  numInputs={4}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={{width: '100%'}}
                  inputStyle={{width: '100%', margin: '5px', height: '3rem'}}
                />
                {formik.touched.otp && formik.errors.otp && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.otp}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row  mb-7'>
                <SelectWithAllOption
                  openMenuOnFocus={true}
                  value={
                    attendanceTypes && attendanceTypes?.length > 0
                      ? attendanceTypes?.find(
                          (item: any) => item.attendanceTypeId === formik.values.attendanceTypeId
                        )
                      : null
                  }
                  onChange={(val: any) =>
                    formik.setFieldValue('attendanceTypeId', val.attendanceTypeId)
                  }
                  options={attendanceTypes}
                  isClearable={true}
                  components={animatedComponents}
                  formatOptionLabel={(item: any) => (
                    <div className='d-flex align-items-center '>
                      <div className='d-flex flex-column'>
                        <p className='p-0 m-0'>{item.attendanceTypeName}</p>
                      </div>
                    </div>
                  )}
                  className='w-100'
                  styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                  theme={(theme: any) => ({
                    ...theme,

                    colors: {
                      ...theme.colors,
                      primary25: 'var(--bs-dark-light)',
                      primary: 'var(--bs-gray)',
                      neutral0: 'var(--bs-dark-light)',
                    },
                  })}
                  placeholder={intl.formatMessage({id: 'KIDLING.ATTENDANCE_TYPE'})}
                />
                {formik.touched.attendanceTypeId && formik.errors.attendanceTypeId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.attendanceTypeId}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-7'>
                <textarea
                  {...formik.getFieldProps('remarks')}
                  className={clsx(
                    'form-control   mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.remarks && formik.errors.remarks},
                    {
                      'is-valid': formik.touched.remarks && !formik.errors.remarks,
                    }
                  )}
                  name='remarks'
                  autoComplete='off'
                  rows={2}
                  placeholder={intl.formatMessage({id: 'KIDLING.HOME.EMPLOYEE_REMARKS'})}
                />
                {formik.touched.remarks && formik.errors.remarks && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.remarks}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className='fv-row mb-7'>
                <label className='  form-label fs-6 fw-semibold text-muted'>
                  {' '}
                  {intl.formatMessage({id: 'KIDLING.HOME.CONTACT_REMARKS'})}
                </label>
                <textarea
                  {...formik.getFieldProps('contact')}
                  className={clsx(
                    'form-control   mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.contact && formik.errors.contact},
                    {
                      'is-valid': formik.touched.contact && !formik.errors.contact,
                    }
                  )}
                  name='contact'
                  autoComplete='off'
                  rows={1}
                />
                {formik.touched.contact && formik.errors.contact && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.contact}</span>
                    </div>
                  </div>
                )}
              </div> */}
            </div>
            {/* end::Scroll */}

            {/* begin::Actions */}

            {/* end::Actions */}
          </>
        </ModalBody>

        <ModalFooter>
          <div className='text-center '>
            <button
              type='reset'
              onClick={() => close()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
            >
              {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              </span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </ModalFooter>
        {formik.isSubmitting && <UsersListLoading />}
      </form>

      <SweetAlert
        show={showSuccess}
        success
        title='Success!'
        onConfirm={(e) => setShowSuccess(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
      {/* <SweetAlert
        show={showErrorMessage}
        error
        title={<ErrorText text='The Label Already Exists!' />}
        onConfirm={(e) => setShowErrorMessage(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      /> */}
    </Modal>
  )
}

export default CheckoutDialog
