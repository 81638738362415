/* eslint-disable react-hooks/exhaustive-deps */
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import './styles.scss'
import MainPage1 from './components/main-page'
import ClassPage from './components/class-page'
import KidsWrapper from './components/kids-page/KidsWrapper'
import EmployeesWrapper from './components/employees-page/EmployeesWrapper'
import usePinboard from '../../../hooks/usePinboard'
import {useEffect} from 'react'

function Main() {
  const {readPinboard} = usePinboard()

  useEffect(() => {
    readPinboard()
  }, [])

  const MainLayout = () => {
    return (
      <div id='kt_app_content_container' className='   position-relative  '>
        <div className='d-flex flex-column flex-lg-row  '>
          <div className='flex-lg-row-fluid   z-index-2'>
            <div className='h-100 '>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid '>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path='main' element={<MainPage1 />} />
          <Route path='kidclass' element={<ClassPage />} />
          <Route path='kids/:classesId' element={<KidsWrapper />} />
          <Route path='employees' element={<EmployeesWrapper />} />
          <Route index element={<Navigate to='main' />} />
        </Route>
      </Routes>
    </div>
  )
}

export default Main
