/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, FC} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Spinner} from 'react-bootstrap'

type Props = {
  filterData: any
}
const KidSearchComponent: FC<Props> = ({filterData}) => {
  const intl = useIntl()
  const [clicked, setClicked] = useState(false)
  const {globalLoader}: any = useAppContext()
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (clicked) {
      filterData(searchText ? searchText : '')
      setClicked(false)
    }
  }, [clicked])

  const handleSubmit = (event: any) => {
    if (event.keyCode === 13) {
      setClicked(true)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleSubmit)

    return () => {
      window.removeEventListener('keydown', handleSubmit)
      setClicked(false)
    }
  }, [])

  return (
    <div className='card-title my-2 w-100  '>
      {/* begin::Search */}
      <div className='d-flex align-items-center w-100  '>
        <div className='d-flex align-items-center position-relative w-100  '>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid  w-100 border border-2 '
            style={{padding: '.6rem 0 .6rem 4rem'}}
            placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <button
          disabled={globalLoader}
          type='button'
          onClick={() => setClicked(true)}
          className='btn btn-primary fw-semibold px-6 py-3  ms-2'
          data-kt-menu-dismiss='true'
          data-kt-user-table-filter='filter'
        >
          {globalLoader ? (
            <Spinner animation={'border'} />
          ) : (
            intl.formatMessage({id: 'COMMON.APPLY'})
          )}
        </button>
      </div>
      {/* end::Search */}
    </div>
  )
}

export {KidSearchComponent}
