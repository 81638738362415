/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useAppContext} from '../../core/AppContextProvider'
import {useAuth} from '../../../../app/modules/auth'
import AvatarWithLetters from '../../../../app/general-components/avatar-with-letters'
import {useThemeMode} from '../../../partials/layout/theme-mode/ThemeModeProvider'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const {currentUserImage, companyMetaData} = useAppContext()
  const {currentUser} = useAuth()
  const {mode} = useThemeMode()
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-center flex-shrink-0'>
        <button
          className='btn btn-icon btn-active-light-primary btn-custom position-relative p-0 '
          onClick={() => window.location.reload()}
        >
          <i
            className='fa-solid fa-arrows-rotate'
            style={{
              color: mode !== 'dark' && companyMetaData.iconColor ? companyMetaData.iconColor : '',
            }}
          ></i>
        </button>

        {/* begin::Theme mode */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div>

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <AvatarWithLetters
              image={currentUserImage}
              alt={currentUser?.usersName?.charAt(0).toUpperCase() ?? ''}
              extrasmall
            />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
  )
}

export {Topbar}
