/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import Select, {components} from 'react-select'
import {useAuth} from '../core/Auth'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {selectStyle} from '../../../utils/select-style'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import AvatarWithLetters from '../../../general-components/avatar-with-letters'

export function Company() {
  const navigate = useNavigate()
  const {setCurrentCompany}: any = useAppContext()
  const {auth} = useAuth()
  const company = window.location.pathname.split('/')[1]
  const intl = useIntl()
  const loginSchema = Yup.object().shape({
    company: Yup.mixed().required(intl.formatMessage({id: 'KIDLING.USERNAME_REQUIRED'})),
  })

  const initialValues = {
    company: {
      value: auth?.companies[0]?.id,
      label: auth?.companies[0].name,
      image: auth?.companies[0]?.image,
    },
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setCurrentCompany(values.company)
      localStorage.setItem('kidling-selectedCompany', JSON.stringify(values.company))
      navigate(company ? `/${company}/kid-management/kids` : '/kidling/kid-management/kids')
    },
  })

  const Input = (props: any) => <components.Input {...props} autoComplete={'nope'} />

  return (
    <div
      className='h-100 mt-16 d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}

        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <div className='d-flex flex-column h-100'>
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form'
            >
              {/* begin::Heading */}
              <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>
                  {intl.formatMessage({id: 'KIDLING.SELECT_COMPANY'})}{' '}
                </h1>
              </div>
              {/* begin::Heading */}

              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              <div className='  col-12 mb-4'>
                <label className='  label  form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.COMPANY'})}
                </label>

                <Select
                  openMenuOnFocus={true}
                  value={formik.values.company}
                  onChange={(val: any) => {
                    formik.setFieldValue('company', val)
                  }}
                  options={auth?.companies?.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                    image: item.image,
                  }))}
                  formatOptionLabel={(item: any) => (
                    <div className='d-flex align-items-center'>
                      <AvatarWithLetters
                        verysmall
                        image={item?.image}
                        alt={item?.label?.charAt(0)?.toUpperCase()}
                        objectContain
                      />
                      <p className='p-0 m-0 ms-4 '>{item?.label}</p>
                    </div>
                  )}
                  isLoading={auth?.companies.length === 0}
                  isDisabled={formik.isSubmitting}
                  theme={(theme) => ({
                    ...theme,

                    colors: {
                      ...theme.colors,
                      primary25: 'var(--bs-dark-light)',
                      primary: 'var(--bs-gray)',
                      neutral0: 'var(--bs-dark-light)',
                    },
                  })}
                  styles={selectStyle}
                  components={{Input}}
                />
              </div>

              {/* begin::Action */}
              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'KIDLING.CONTINUE'})}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}

      {/* end::Footer */}
    </div>
  )
}
